@tailwind base;
@tailwind components;
@tailwind utilities;
@import "react-spring-bottom-sheet";

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }

  #root {
    height: 100vh;
    width: 100%;
    display: flex;
  }

  /* Avoid Chrome to see Safari hack */
  @supports (-webkit-touch-callout: none) {
    #root {
      /* The hack for Safari */
      height: -webkit-fill-available;
    }
  }
}

@layer components {

  html .video-js .vjs-tech {
    @apply object-cover;
  }
  html .video-js .vjs-text-track-display {
    @apply bottom-12;
  }
  html .video-js .vjs-control-bar {
    @apply h-12 bg-black bg-opacity-60;
  }
  html .video-js .vjs-time-control.vjs-control {
    @apply w-auto flex items-center text-xs sm:text-sm leading-tight;
  }
  html .video-js .vjs-control {
    @apply w-12;
  }
  html .video-js .vjs-button > .vjs-icon-placeholder:before {
    @apply text-xl;
    line-height: 3rem;
  }
  html .video-js .vjs-slider {
    @apply h-1 bg-white bg-opacity-30;
  }
  html .video-js .vjs-big-play-button {
    display: none;
  }

}

:root {
  --rsbs-backdrop-bg: rgba(0, 0, 0, 0.6);
  --rsbs-bg: #fff;
  --rsbs-handle-bg: hsla(0, 0%, 0%, 0.14);
  --rsbs-max-w: 440px;
  --rsbs-ml: auto;
  --rsbs-mr: auto;
  --rsbs-overlay-rounded: 16px;
}

[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  z-index: 100;
}
