.animation-move-up {
  transform-origin: 50% 50%;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-name: move-up;
  animation-fill-mode: forwards;
}

.animation-wiggle {
  transform-origin: 50% 50%;
  animation-duration: 2s;
  animation-name: wiggle;
  animation-iteration-count: infinite;
}

@keyframes move-up {
  0% {
    transform: scale(0.75);
    bottom: 0;
    opacity: 0;
  }
  20% {
    transform: scale(1);
    bottom: 15%;
    opacity: 1;
  }
  100% {
    transform: scale(0.85);
    bottom: 80%;
    opacity: 0;
  }
}

@keyframes wiggle {
  0% {
    transform: translate(-10%, 0px);
  }
  50% {
    transform: translate(+10%, 0px);
  }
  100% {
    transform: translate(-10%, 0px);
  }
}